<template>
  <ul class="tabs justify-content-start justify-content-xl-between mb-0">
    <li v-for="(tab, index) in tabs" :key="index">
      <button @click="$emit('setTab', tab)" :class="{ active: currentTab === tab }">{{ $t(`statistic.tabs.${tab}`) }}</button>
    </li>
  </ul>
</template>
<script>
import "@/assets/css/_tubs.scss";

export default {
  name: "Tabs",
  props: {
    tabs: {
      type: Array,
      required: true,
    },
    setTab: {
      type: Function,
      default: () => {},
    },
    currentTab: {
      type: String,
      default: "",
    },
  },
};
</script>
